<template>
  <div class="nav-container">
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo">
      <div v-for="(firstMenuItem, firstMenuIndex) in rbacList" :key="firstMenuIndex">
        <div v-for="(secondMenuItem, secondMenuIndex) in firstMenuItem.child" :key="secondMenuIndex">
          <template v-if="secondMenuItem.child">
            <el-submenu :index="firstMenuIndex + '-' + secondMenuIndex">
              <template slot="title">
                <span>{{ secondMenuItem.name }}</span>
              </template>
              <router-link :to="thirdMenuItem.router" v-for="(thirdMenuItem, thirdMenuIndex) in secondMenuItem.child" :key="thirdMenuIndex">
                <el-menu-item  :index="firstMenuIndex + '-' + secondMenuIndex + '-' + thirdMenuIndex">
                  {{ thirdMenuItem.name }}
                </el-menu-item>
              </router-link>
            </el-submenu>
          </template>
          <template v-else>
            <router-link :to="secondMenuItem.router">
              <el-menu-item :index="firstMenuIndex + '-' + secondMenuIndex">{{ secondMenuItem.name }}</el-menu-item>
            </router-link>
          </template>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import localStorage from '@/utils/localStorage'
  export default {
    name: 'Menu',
    data () {
      return {
        activeMenu: '',
        rbacList: []
      }
    },
    computed: {
      ...mapGetters([
        'activeTab'
      ])
    },
    methods: {
      handleSelect (key, keyPath) {
       this.activeMenu = key
      },
      initMenu () {
        let that = this
        that.activeMenu = that.$route.path
        let _rbacList = localStorage.get('rbacList')
        console.log('_rbacList ==> ', _rbacList)
        if (_rbacList && _rbacList.length > 0) {
          that.$store.commit('UPDATE_TAB', 0)
          that.rbacList = _rbacList
          // 菜单渲染结束，定位当前菜单
          that.$nextTick(() => {
            that.initTab(that.activeMenu)
          })
        }
      },
      initTab (_activeMenu) {
        let that = this
        that.rbacList.forEach((e, i) => {
          if (e.child) {
            e.child.forEach((e2, i2) => {
              if (e2.child) {
                e2.child.forEach((e3, i3) => {
                  if (e3.router === _activeMenu) {
                    this.$store.commit('UPDATE_TAB', i)
                  }
                })
              } else {
                if (e2.router === _activeMenu) {
                  this.$store.commit('UPDATE_TAB', i)
                }
              }
            })
          }
        })
      }
    },
    mounted () {
      this.initMenu()
    }
  }
</script>

<style lang="less" scoped>
.el-aside {
  height: calc(100vh - 60px);
  overflow: hidden;
}
  .nav-container {
    height: 100%;
    overflow: hidden;
    &>ul {
      height: 100%;
      overflow-y: scroll;
      a {
        color: #333;
        display: block;
        text-decoration: none;
      }
    }
    :deep(.el-menu--inline) {
      background: #f8f8f8;
    }
  }
</style>
<style lang="less" scoped>
  @import "../../assets/less/nav";
</style>
