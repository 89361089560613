import store from '@/utils/localStorage'
const LOGIN_KEY = 'token'
const USER_INFO_KEY = 'userInfo'
const state = {
  token: store.get(LOGIN_KEY) || null,
  userInfo: store.get(USER_INFO_KEY),
  activeTab: -1,
  isTopSite: store.get(USER_INFO_KEY).platformId === 0
}

const mutations = {
  LOGIN (state, token) {
    state.token = token
    store.set(LOGIN_KEY, token)
  },
  LOGOUT (state) {
    state.token = undefined
    store.remove(LOGIN_KEY)
    store.remove(LOGIN_KEY)
  },
  UPDATE_USERINFO (state, userInfo) {
    state.userInfo = userInfo
    store.set(USER_INFO_KEY, userInfo)
  },
  UPDATE_TAB (state, tab) {
    state.activeTab = tab
  }
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
