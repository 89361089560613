<template>
  <header class="header">
    <div class="header-left">
      <h3>118图库后台管理</h3>
    </div>
    <div class="header-right navbar-top">
<!--      <div class="header-news">-->
<!--        <div class="el-badge item ">-->
<!--          <sup class="el-badge__content is-fixed" v-show="msgCount>0">{{ msgCount }}</sup>-->
<!--        </div>-->
<!--      </div>-->
      <div class="header-account">
        <el-popover placement="bottom" width="300" trigger="hover" popper-class="user-info">
          <div class="base-info media-object">
            <div class="media-object-section shrink">
              <div class="avatar">
                <img src="@/assets/image/default-avatar.png">
              </div>
            </div>
            <div class="media-object-section information-content">
              <div><span class="color-blue information-title">{{ userInfo.username }}</span></div>
              <div class="margin-t6"><span class="color-grey">最近登录时间:</span> <span class="color-grey">{{ userInfo.loginTime | dateFormat('MM-dd hh:mm:ss') }}</span></div>
            </div>
          </div>
          <div class="btn-user">
            <!-- <router-link to="/memberUserInfo">修改资料</router-link>-->
            <el-button @click="logout" size="mini" icon="el-icon-switch-button">退出登录</el-button>
            <el-button @click="editPwdVisible = true" size="mini" icon="el-icon-key">修改密码</el-button>
          </div>
          <img slot="reference" class="avatar" src="@/assets/image/default-avatar.png">
        </el-popover>
      </div>
      <span></span>
    </div>

    <el-dialog
      title="修改登录密码"
      :visible.sync="editPwdVisible"
      width="30%"
      :close-on-click-modal="false"
      customClass="customWidth"
      custom-class="mini-reveal-modal"
      append-to-body
    >
      <el-form :model="editForm" :rules="pwdRules" ref="pwdForm">
        <el-form-item label="登录密码：" prop="password">
          <el-input placeholder="请输入登录密码" show-password v-model="editForm.password"></el-input>
        </el-form-item>
        <el-form-item label="再次输入：" prop="secondPwd">
          <el-input placeholder="请再次输入登录密码" show-password v-model="editForm.secondPwd"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-form-footer">
        <el-button size="mini" @click="editPwdVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="editPwdSubmit" :loading="btnLoading">确 定</el-button>
      </div>

    </el-dialog>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'
  import localStorage from '@/utils/localStorage'
  import { updAdminPwd } from '@/api/platform'
  export default {
    name: 'Header',
    props: {
      onlyTop: { // 顶部菜单栏简易模式
        type: Boolean,
        default: false
      }
    },
    data () {
      var checkPwd = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入登录密码!'))
        } else
        if (value !== this.editForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        popoverVisible: false,
        msgCount: 0,
        platformName: '管理后台',
        editPwdVisible: false,
        editForm: {
          password: '',
          secondPwd: ''
        },
        pwdRules: {
          password: [
            { required: true, message: '请输入登录密码', trigger: 'blur' }
          ],
          secondPwd: [
            { required: true, validator: checkPwd, trigger: 'blur' }
          ]
        },
        btnLoading: false
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ])
    },
    methods: {

      logout () {
        this.$store.commit('LOGOUT')
        this.$router.replace('/login')
      },
      editPwdSubmit () {
        this.$refs['pwdForm'].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            updAdminPwd({ password: this.editForm.password })
            .then(res => {
              this.btnLoading = false
              if (!res.code) {
                this.$message({
                  type: 'success',
                  message: '修改成功!3s后跳转到登录页重新登录',
                  duration: 3000,
                  onClose: () => {
                    this.$store.commit('LOGOUT')
                    this.$router.replace('/login')
                  }
                })
              } else {
                this.$message.error(res.msg || '修改失败')
              }
            })
            .catch(() => {
              this.btnLoading = false
            })
          } else {
            return false
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../assets/less/nav";

</style>
