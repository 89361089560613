// 公共搜索组件块
import SearchContainer from './searchContainer'
// 公共操作按钮组件块
import ButtonContainer from './buttonContainer'
// 公共表格组件块
import TableContainer from './tableContainer'
// 公共分页组件块
import PaginationContainer from './paginationContainer'
const plugins = {
  install: function (Vue) {
    Vue.component('v-search', SearchContainer)
    Vue.component('v-button', ButtonContainer)
    Vue.component('v-table', TableContainer)
    Vue.component('v-pagination', PaginationContainer)
  }
}

export default plugins
