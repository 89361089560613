const localStorage = () => {
	return window.localStorage
}
const get = (key) => {
	if (!key) return
	const result = localStorage().getItem(key)
	if (result) {
		try {
			return JSON.parse(result)
		} catch (e) {
			return result
		}
	} else {
		return false
	}
}
const set = (key, value) => {
	if (!key) return
	let val = value
	if (typeof value !== 'string') { val = JSON.stringify(value) }

	return localStorage().setItem(key, val)
}
const all = () => {
	const data = {}
	for (var i = localStorage().length - 1; i >= 0; i--) {
		var key = localStorage().key(i)
		data[key] = get(key)
	}
	return data
}
const remove = (key) => {
	if (!key) return
	return localStorage().removeItem(key)
}
const clearAll = () => {
	return localStorage().clear()
}
const has = function (key) {
	return localStorage().getItem(key) !== null
}
export default { get, set, all, remove, clearAll, has }
