<template>
  <div class="wrapper">
    <keep-alive :exclude="exclude">
      <router-view v-if="this.$route.meta.keepAlive" :key="key">
        <!--这里是会被缓存的路由-->
      </router-view>
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive" :key="key">
      <!--因为用的是v-if 所以下面还要创建一个未缓存的路由视图出口-->
    </router-view>
  </div>
</template>

<script>
  function notKeepAlive ($route) {
    return !$route.meta.keepAlive
  }

  export default {
    name: 'Main',
    data () {
      return {
        showRefreshImg: false,
        exclude: notKeepAlive(this.$route) ? [this.$route.name] : [],
        excludeRouterName: []
      }
    },
    methods: {},
    computed: {
      key () {
        return this.$route.path
      }
    },
    watch: {
      '$route' (to, from) {
        // keepAlive缓存机制刷新
        if (to.meta.keepAlive && to.meta.keepAliveChange) {
          this.excludeRouterName.push(to.name)
          this.$nextTick(_ => {
            this.excludeRouterName.pop()
          })
        }
      }
    }
  }
</script>

<style>

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

</style>
