<template>
  <div class="layout-container">
    <el-container>
      <el-header>
        <Header/>
      </el-header>
      <el-container class="layout-main">
        <el-aside width="220px">
          <Menu />
        </el-aside>
        <el-main>
          <Main/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import { Header, Menu, Main } from './components'

  export default {
    name: 'Layout',
    data () {
      return {}
    },
    components: {
      Header,
      Menu,
      Main
    }
  }
</script>

<style lang="less" scoped>
  .layout-container {
    .layout-main {
      height: calc(100vh - 60px);
      overflow: hidden;
    }
  }
</style>
